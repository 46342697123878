import styled from "styled-components";
import { useContext, useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  getFontSize,
  TextField,
  Button,
  AsurionDoodleSpinner,
  Text,
  Checkbox,
} from "@soluto-private/mx-asurion-ui-react";
import * as Yup from "yup";
import { ENTITY_TYPES } from "@apim/lib-portal-entities";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { useUpdateProduct, useAddProduct } from "../../api";
import { Dropdown } from "../Dropdown";
import { GenericDTO, PRODUCT_NAME_VALIDATION } from "../../../constants";
import { UserOwnershipContext } from "../../hooks";

const ProductFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Product name required to be at least 3 characters long")
    .matches(
      PRODUCT_NAME_VALIDATION,
      "Only alphanumeric characters are allowed for the API name."
    )
    .required("Product name required."),
  description: Yup.string()
    .min(10, "Product description required to at least 10 characters long")
    .required("Product name required."),
});

// TODO specify the type definition
const STATUS_OPTIONS = [
  { name: "Draft", value: "DRAFT" },
  { name: "Completed", value: "COMPLETE" },
];

const StyledForm = styled.form`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.secondaryBackgroundColor};
  padding: 0;
  margin: 0;
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.secondaryBackgroundColor};
  border-radius: 10px;
  padding: 20px;
  min-width: 400px;
  width: 40vw;
  height: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
  height: 80px;
`;

const Body = styled.div`
  display: flex;
  flex-grow: 0;
  height: auto;
  padding: 1em;
`;

const Title = styled.h1`
  color: ${(props) => props.theme.invertedTextOnSecondary};
  font-size: ${getFontSize(3)};
`;

const Row = styled.div<{ flexAlign?: "right" | "left" }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: ${({ flexAlign }) =>
    flexAlign === "right" ? "flex-end" : "flex-start"};
  margin: 20px 0px;
  padding: 0px;
`;

const FieldContainer = styled.div`
  && {
    min-width: 45%;
    width: 100%;
    padding: 0 10px;
  }
`;

const SubmitButton = styled(Button)`
  align-self: flex-end;
`;

const StyledCheckbox = styled(Checkbox)`
  &&& ~ span.ui-checkbox__desc {
    color: ${(props) => props.theme.textOnPrimary} !important;
  }
`;

interface EditProductFormProps {
  product?: GenericDTO;
  handleCancel?: any;
  handleSuccess?: (params?: any) => void;
}

interface Product {
  name: string;
  description: string;
  status: string;
  isPublic: boolean;
  type: ENTITY_TYPES.PRODUCT;
}

export function EditProductForm(props: EditProductFormProps) {
  const userOwnership = useContext(UserOwnershipContext);
  const { product, handleCancel, handleSuccess } = props;
  const [productName, setProductName] = useState(product?.pk ?? "");
  const history = useHistory();
  const {
    error: createProductError,
    invokeApi: createProduct,
    isLoading: isCreateLoading,
    response: creationResponse,
  } = useAddProduct(productName as string);
  const {
    error: updateProductError,
    invokeApi: updateProduct,
    isLoading: isEditLoading,
    response: updateResponse,
  } = useUpdateProduct((product?.pk ?? productName) as string);

  const onSubmit = async (values: any) => {
    const payload = { ...values, pk: values.name, sk: "INFO" };
    if (product) {
      await updateProduct(null, payload);
    } else {
      await createProduct(null, payload);
    }
    if (!createProductError && !updateProductError) {
      if (!userOwnership.products?.includes(payload.pk)) {
        userOwnership.products?.push(payload.pk);
      }
      handleSuccess && handleSuccess(payload);
      handleCancel && handleCancel();
    }
  };

  useEffect(() => {
    if (updateProductError) {
      toast.error(updateProductError?.response?.data);
    }

    if (updateResponse) {
      toast.success("Your request was completed successfully.");
      history.push(`/products/${updateResponse.name}`);
      window.location.reload();
    }

    if (creationResponse) {
      toast.success("Your request was completed successfully.");
      history.push(`/products/${creationResponse.name}`);
      window.location.reload();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [updateProductError, updateResponse, creationResponse]);

  const formik = useFormik({
    initialValues: {
      name: product?.pk || productName,
      description: product?.description || "",
      status: product?.status || "DRAFT",
      isPublic: !!product?.isPublic,
      type: "PRODUCT",
    } as Product,
    onSubmit,
    validateOnBlur: true,
    validationSchema: ProductFormSchema,
  });

  return (
    <StyledContainer>
      <Header>
        <Title>
          {product ? `Editing ${product?.pk}` : "Create new product"}
        </Title>
        <Button color="secondary" variant="flat" onClick={handleCancel}>
          Cancel
        </Button>
      </Header>
      <Body>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Row>
            <FieldContainer>
              <TextField
                label="Name"
                color="secondary"
                defaultValue={formik.values.name}
                name="name"
                fieldStatus={formik.errors.name ? "error" : "default"}
                helperText={formik.errors.name ? formik.errors.name : ""}
                onChange={(value) => {
                  formik.handleChange(value);
                  setProductName(value.target.value);
                }}
              />
            </FieldContainer>
          </Row>
          <Row>
            <FieldContainer>
              <TextField
                label="Description"
                name="description"
                fieldStatus={formik.errors.description ? "error" : "default"}
                defaultValue={formik.values.description}
                helperText={
                  formik.errors.description ? formik.errors.description : ""
                }
                onChange={formik.handleChange}
              />
            </FieldContainer>
          </Row>
          <Row>
            <FieldContainer>
              <Dropdown
                options={STATUS_OPTIONS}
                label="Status"
                name="status"
                value={formik.values.status}
                onChange={formik.handleChange}
              />
            </FieldContainer>
            <FieldContainer>
              <StyledCheckbox
                style={{
                  marginTop: "24px",
                }}
                checked={formik.values.isPublic}
                label="Public"
                color="secondary"
                onChange={formik.handleChange}
                name="isPublic"
                className="styled-checkbox"
              />
            </FieldContainer>
          </Row>
          <Row flexAlign="right">
            <SubmitButton
              type="submit"
              color="secondary"
              disabled={!!(isEditLoading || isCreateLoading)}
            >
              <>
                <Text>Save</Text>
                {(isEditLoading || isCreateLoading) && (
                  <AsurionDoodleSpinner width="20px" />
                )}
              </>
            </SubmitButton>
          </Row>
        </StyledForm>
      </Body>
    </StyledContainer>
  );
}

export default EditProductForm;
