import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ErrorPage } from "@soluto-private/mx-asurion-ui-react";
import { toast } from "react-toastify";
import Modal from "styled-react-modal";
import { useGetProductRecords, useDeleteProduct } from "../../lib/api";
import {
  CreateProductDoc,
  EditUserForm,
  LinkApiForm,
  Sidebar,
} from "../../lib/components";
import {
  ProductWrapper,
  SidebarContentWrapper,
  ProductContainer,
  ErrorContainer,
  StyledScrollLink,
  ProductSkeletonWrapper,
  StyledButtonSidebar,
} from "./elements/elements";
import ProductCollapsiblePanel from "./elements/ProductCollapsiblePanel";
import ProductContent from "./elements/ProductContent";
import { UserOwnershipContext } from "../../lib/hooks";
import ProductSkeleton from "./elements/ProductSkeleton";

export function Product() {
  const { productName, productDoc } = useParams() as any;
  const history = useHistory();
  const [product, setProduct] = useState<any>();
  const [productDocs, setProductDocs] = useState<any[]>([]);
  const [productApis, setProductApis] = useState<string[]>([]);
  const [canModifyProduct, setCanModifyProduct] = useState<boolean>(false);
  const userOwnership = useContext(UserOwnershipContext);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [userTabRefresh, setUserTabRefresh] = useState(false);
  const [modalContent, setModalContent] = useState("api");

  const {
    response: productResponse,
    isLoading,
    error,
    invokeApi: getProductRecords,
  } = useGetProductRecords(productName);

  const {
    invokeApi: deleteProduct,
    isLoading: isDeletingProduct,
    error: deleteProductError,
    response: deleteProductResponse,
  } = useDeleteProduct(productName);

  useEffect(() => {
    getProductRecords();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [productName]);

  useEffect(() => {
    if (productResponse) {
      const apis = productResponse.apis.map((value: any) => {
        return {
          ...value,
          title: value.pk,
        };
      });
      const docs = productResponse.docs.map((value: any) => {
        return {
          title: value.sk.split("#")[1],
          documentPath: value.payload,
          signedUrl: value.signedUrl,
        };
      });
      setProduct(productResponse);
      setProductApis(apis);
      setProductDocs(docs);
    }
  }, [productResponse]);

  useEffect(() => {
    if (userOwnership) {
      if (userOwnership.isInternal) {
        if (userOwnership?.products?.indexOf(productName) !== -1) {
          setCanModifyProduct(true);
        }
      } else {
        setCanModifyProduct(false);
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [userOwnership.isInternal, userOwnership.products]);

  useEffect(() => {
    if (deleteProductResponse) {
      toast.success(`Successfully deleted ${productName}`);
      history.push("/products");
    } else if (deleteProductError) {
      console.log(deleteProductError);
      toast.error("Something went wrong. Please try again.");
    }
  }, [deleteProductResponse, deleteProductError]);

  const handleDocumentTitleChange = (
    previousDocumentTitle: string,
    newDocumentTitle: string
  ) => {
    const position = productDocs
      .map((item: any) => item.title)
      .indexOf(previousDocumentTitle);
    setProductDocs((prevState: any[]) => {
      const tempArray = [...prevState];
      tempArray[position].title = newDocumentTitle;
      return tempArray;
    });
  };

  const toggleModalForm = () => {
    setIsFormModalOpen((prevState) => !prevState);
  };

  return (
    <ProductWrapper>
      {error && error.response && error.response.status === 403 ? (
        <ErrorContainer>
          <ErrorPage
            subtitle="Please contact an Asurion representative if you need access for this product."
            title="Sorry, you don't have permission to view this product"
            actionLabel="Go back to previous page"
            button={{
              onClick: () => history.goBack(),
              color: "secondary",
            }}
          />
        </ErrorContainer>
      ) : (
        <>
          <Sidebar open productSidebar>
            <SidebarContentWrapper>
              <StyledScrollLink
                activeClass="active-link"
                to="overview"
                spy
                smooth
                duration={50}
                offset={-75}
              >
                Overview
              </StyledScrollLink>
              <ProductCollapsiblePanel
                keyName="DOCUMENTS"
                items={productDocs}
                expand
              />
              <ProductCollapsiblePanel
                keyName="API"
                items={productApis}
                expand
              />
              {canModifyProduct && (
                <>
                  <StyledButtonSidebar
                    disabled={isLoading}
                    color="secondary"
                    variant="text"
                    key="doc-btn-key"
                    size="medium"
                    onClick={() => {
                      history.push(`/products/${productName}/docs/new`);
                    }}
                  >
                    Create new document
                  </StyledButtonSidebar>
                  <StyledButtonSidebar
                    disabled={isLoading}
                    color="secondary"
                    variant="text"
                    key="doc-btn-key"
                    size="medium"
                    onClick={() => {
                      setModalContent("api");
                      toggleModalForm();
                    }}
                  >
                    Link an API
                  </StyledButtonSidebar>
                  {canModifyProduct &&
                    !userOwnership.archProducts?.includes(productName) && (
                      <StyledButtonSidebar
                        disabled={isLoading}
                        color="secondary"
                        variant="text"
                        key="doc-btn-key"
                        size="medium"
                        onClick={() => {
                          setModalContent("user");
                          toggleModalForm();
                        }}
                      >
                        Add product users
                      </StyledButtonSidebar>
                    )}
                </>
              )}
            </SidebarContentWrapper>
          </Sidebar>
          <ProductContainer>
            {/* eslint-disable*/}
            {isLoading ? (
              <ProductSkeletonWrapper>
                <ProductSkeleton />
              </ProductSkeletonWrapper>
            ) : productDoc === "new" ? (
              <CreateProductDoc product={productName} />
            ) : (
              <ProductContent
                product={product}
                getProductRecords={getProductRecords}
                deleteProduct={deleteProduct}
                isDeletingProduct={isDeletingProduct}
                setDocumentTitle={handleDocumentTitleChange}
                docs={productDocs}
                apis={productApis}
                userTabRefresh={userTabRefresh}
                canModifyProduct={canModifyProduct}
              />
            )}
          </ProductContainer>
        </>
      )}
      <Modal isOpen={isFormModalOpen} onBackgroundClick={toggleModalForm}>
        {modalContent === "api" ? (
          <LinkApiForm
            onCancel={toggleModalForm}
            product={productName}
            handleSuccess={() => {
              toggleModalForm();
              getProductRecords();
            }}
            {...product}
          />
        ) : (
          <EditUserForm
            onSubmitCallback={() => {
              toggleModalForm();
              setUserTabRefresh(!userTabRefresh)
            }}
            onCancel={toggleModalForm}
            productName={productName}
            {...product}
          />
        )}
      </Modal>
    </ProductWrapper>
  );
}

export default Product;
