import { HttpMethod, UserInfo, UserRole } from "../../constants";
import { useApiCall } from "../hooks";

export interface InternalUserDTO {
  company: string;
  firstname: string;
  lastname: string;
  pk: string;
  sk: string;
}

export function useGetInternalUsers(name: string) {
  const { invokeApi: getUsers, ...rest } = useApiCall<
    InternalUserDTO[] | null | undefined
  >(`/access/user/company/${name}`);
  return { ...rest, getUsers };
}

export function useGrantUserTeamAccess(userid: string) {
  return useApiCall<any | null | undefined>(
    `/access/user/${userid}/team`,
    HttpMethod.PUT
  );
}
export function useGetUsers() {
  return useApiCall<UserInfo[]>("/access/user");
}

export function useGetUsersByProduct(productName: string) {
  return useApiCall<UserRole[]>(`/access/user/product/${productName}`);
}

export function useGetUserByCompany(companyName: string) {
  return useApiCall<UserInfo[]>(`/access/user/company/${companyName}`);
}

export function useGetUserRole(userId: string) {
  return useApiCall<UserRole[]>(`/access/user/${userId}/roles`);
}

export function useDeleteUserRole(userId: string) {
  return useApiCall<UserRole>(`/access/user/${userId}/role`, HttpMethod.DELETE);
}
