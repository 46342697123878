import { Button, getFontSize } from "@soluto-private/mx-asurion-ui-react";
import { useEffect, useState } from "react";
import { Link } from "react-scroll";
import styled from "styled-components";

interface CollapsePanelProps {
  keyName: string;
  items: any;
  expand?: boolean;
}

const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 5px;
`;

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.textOnPrimary};
  margin: ${getFontSize(1)} 0 0 -10px;
  padding-left: 5px;
  text-decoration: none;
  &&& {
    &:hover {
      color: ${(props) => props.theme.invertedTextOnPrimary};
      background-color: transparent;
    }
  }
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 0.01em;
`;

const StyledLink = styled(Link)`
  color: ${(props) => props.theme.textOnPrimary};
  width: 100%;
  padding-left: 10px;
  font-size: 1rem;
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.invertedTextOnPrimary};
  }

  &:hover.active-link {
    border-radius: 8px;
    background: ${(props) => props.theme.secondaryBackgroundColor};
  }

  &.active-link {
    border-radius: 8px;
    background: ${(props) => props.theme.secondaryBackgroundColor};
  }
`;

function ProductCollapsiblePanel({
  keyName,
  items,
  expand,
}: CollapsePanelProps) {
  const [iconSrc, setIconSrc] = useState<string>("ChevronRight");
  const [hidden, setHidden] = useState<boolean>(false);
  const iconSrcCtrl = () => {
    if (iconSrc === "ChevronRight") {
      setIconSrc("ChevronDown");
      setHidden(false);
    } else {
      setIconSrc("ChevronRight");
      setHidden(true);
    }
  };

  useEffect(() => {
    if (expand) {
      setHidden(!expand);
      setIconSrc("ChevronDown");
    }
  }, [expand]);

  return (
    <>
      <StyledButton
        ariaLabel="ChevronRight"
        iconSide="right"
        iconSrc={iconSrc}
        size="medium"
        variant="text"
        onClick={() => iconSrcCtrl()}
      >
        {keyName}
      </StyledButton>
      <div hidden={hidden}>
        {items &&
          items.map((item: any) => {
            return (
              <LinkWrapper key={item.title}>
                <StyledLink
                  activeClass="active-link"
                  to={item.title}
                  spy
                  smooth
                  duration={50}
                  offset={-75}
                  isDynamic
                >
                  {item.title}
                </StyledLink>
              </LinkWrapper>
            );
          })}
      </div>
    </>
  );
}

export default ProductCollapsiblePanel;
