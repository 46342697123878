import {
  colors,
  Tag,
  Text,
  AsurionDoodleSpinner,
  DynamicGrid,
  Card,
} from "@soluto-private/mx-asurion-ui-react";
import { useContext } from "react";
import moment from "moment";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroller";
import { ENTITY_STATI } from "@apim/lib-portal-entities";
import { useHistory } from "react-router-dom";

import { GenericDTO } from "../../constants";
import { UndecoratedLink, Button } from "../../lib/components";
import { Product } from "../../models";
import { UserOwnershipContext } from "../../lib/hooks";

export const ProductListContainer = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
  margin: 12px auto;
`;

const ProductCardBase = styled(Card)`
  margin-bottom: 16px;
  padding: 40px;
  background-color: ${(props) => props.theme.secondaryBackgroundColor};
`;
const StyledTag = styled(Tag)`
  background-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.secondaryBackgroundColor};
`;

const StyledButton = styled(Button)`
  &&& svg {
    filter: ${({ theme }) => {
      const { textOnPrimary } = theme;
      return (textOnPrimary as string) === "#C1C9D2"
        ? `invert(91%) sepia(21%) saturate(129%) hue-rotate(178deg) brightness(86%) contrast(89%);`
        : `invert(21%) sepia(22%) saturate(480%) hue-rotate(163deg) brightness(95%) contrast(90%);`;
    }};
  }

  &&&:hover {
    background-color: ${({ theme }) => theme.secondaryBackgroundColor};
    border-color: ${({ theme }) => theme.secondaryBackgroundColor};
  }
`;

const ProductTitleText = styled(Text)`
  &&& {
    display: block;
    margin-bottom: 5px;
    margin-top: 5px;
    color: ${(props) => props.theme.invertedTextOnSecondary} !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30vw;
    text-align: left;
  }
`;

const ProductDescriptionText = styled.div`
  margin-bottom: 5px;
  margin-top: 5px;
  color: ${(props) => props.theme.invertedTextOnSecondary};
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  max-height: 76px;
  height: 76px;
  overflow: hidden;
  word-break: break-word;
  text-align: left;
`;

const ProductCreationText = styled(Text)`
  color: ${(props) => props.theme.invertedTextOnSecondary} !important;
`;

const ProductMetaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 12px;
`;

const ProductTagsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
`;

const ProductMetaLink = styled(UndecoratedLink)`
  color: ${(props) => props.theme.invertedTextOnSecondary};
`;

const StyledSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ProductBody = styled.div`
  text-align: left;
  margin-bottom: 12px;
`;

const ProductFooter = styled.div`
  border-top: 1px solid ${colors.neutralBright};
  text-align: left;
`;

interface IProductListProps {
  products: (Product & GenericDTO)[];
  loadMore: () => void;
  lastKey?: any;
}

type ProductListProps = IProductListProps;

interface IProductCardProps {
  product: Product & GenericDTO;
  redirectTo: (link: string) => void;
}

// Interfaces for pagination
export interface ProductsPagination {
  currentProducts: (Product & GenericDTO)[];
  productsPerPage?: number;
}
// End for pagination interfaces

type ProductCardProps = IProductCardProps;

const formatDate = (dateString: any) => {
  if (dateString && typeof dateString !== "boolean") {
    return moment(dateString).format("MM/DD/YYYY");
  }
  return moment().format("MM/DD/YYYY");
};

/**
 * Render Product Card
 *
 * @param product Product Item
 */
function ProductCard({ product, redirectTo }: ProductCardProps) {
  const user = useContext(UserOwnershipContext);
  return (
    <ProductCardBase
      type="call-to-action"
      onClick={() => redirectTo(`/products/${product.pk}`)}
    >
      <ProductBody>
        <ProductTagsContainer>
          {user.isInternal && (
            <>
              <StyledTag
                size="regular"
                text={product.isPublic ? "Public" : "Private"}
                type={product.isPublic ? "attention" : "gray"}
                key="isPublic"
              />
              <StyledTag
                size="regular"
                text={
                  product.status === ENTITY_STATI.DRAFT
                    ? "In progress"
                    : "Completed"
                }
                type={
                  product.status === ENTITY_STATI.DRAFT ? "gray" : "attention"
                }
                key="status"
              />
            </>
          )}
        </ProductTagsContainer>
        <ProductMetaContainer>
          <ProductTitleText size={4} weight="heavy" title={`${product.pk}`}>
            {product.pk}
          </ProductTitleText>
        </ProductMetaContainer>
        <ProductDescriptionText>{product.description}</ProductDescriptionText>
        <ProductCreationText size={1}>
          Created on: {formatDate(product.createdAt)}
        </ProductCreationText>
        <ProductMetaContainer>
          <ProductMetaLink to={`/products/${product.pk}/apis`}>
            {product.apiCount || 0} APIs
          </ProductMetaLink>
          <ProductMetaLink to={`/products/${product.pk}/attachments`}>
            {product.docCount || 0} Docs
          </ProductMetaLink>
        </ProductMetaContainer>
      </ProductBody>
      <ProductFooter>
        {
          // eslint-enable
        }
        <ProductMetaContainer>
          <StyledButton
            ariaLabel="AsurionLogo"
            iconSide="right"
            iconSrc="AsurionLogo"
            color="secondary"
            options={{
              useFigmaStyles: false,
            }}
            size="small"
            variant="flat"
          />
          <ProductMetaLink to={`/products/${product.pk}`}>
            <Button size="small" variant="outline" color="secondary">
              Explore
            </Button>
          </ProductMetaLink>
        </ProductMetaContainer>
      </ProductFooter>
    </ProductCardBase>
  );
}

// For pagination purpose making it as component
function ProductDisplayList({ currentProducts, loadMore, lastKey }: any) {
  const history = useHistory();

  const redirectTo = (link: string) => {
    history.push(link);
  };

  return (
    <InfiniteScroll
      loadMore={loadMore}
      hasMore={!!lastKey}
      loader={
        <StyledSpinner>
          <AsurionDoodleSpinner
            title="Asurion dots loader demo SVG"
            width="120px"
          />
        </StyledSpinner>
      }
    >
      {currentProducts.length > 0 && (
        <DynamicGrid gapSize={1} minWidth={25} maxColumns={3}>
          {currentProducts.map((product: any) => (
            <div key={product.pk}>
              <ProductCard product={product} redirectTo={redirectTo} />
            </div>
          ))}
        </DynamicGrid>
      )}
    </InfiniteScroll>
  );
}

export function ProductList({ products, loadMore, lastKey }: ProductListProps) {
  return (
    <ProductListContainer>
      <ProductDisplayList
        currentProducts={products}
        loadMore={loadMore}
        lastKey={lastKey}
      />
    </ProductListContainer>
  );
}

export default ProductList;
