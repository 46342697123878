import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ErrorPage, Switch } from "@soluto-private/mx-asurion-ui-react";
import Modal from "styled-react-modal";
import { ENTITY_STATI } from "@apim/lib-portal-entities";

import { ProductList } from "./ProductList";
import {
  EditProductForm,
  ProductListSkeleton,
  Button,
  Dropdown,
} from "../../lib/components";
import { useGetProductsPaginated } from "../../lib/api";
import { GenericDTO } from "../../constants";
import { Product } from "../../models";
import { SearchBarList } from "../../lib/components/Searchbar";
import { useUser, UserOwnershipContext } from "../../lib/hooks";

const ProductsWrapper = styled.div`
  width: 90vw;
  padding: 32px 15px 0 15px;
  margin: 0 auto;
`;

const ProductActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10vh;
`;

const SearchDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 10vh;
  column-gap: 10px;
`;

const StyledDropdown = styled.div`
  width: 15vw;
`;

const StyledSwitch = styled(Switch)`
  [type="checkbox"]:checked + span::before {
    background: ${({ theme }) => theme.primary};
  }
`;

const filterByOptions = [
  {
    name: "All",
    value: "All",
  },
  {
    name: "Completed",
    value: ENTITY_STATI.COMPLETE,
  },
  {
    name: "In Progress",
    value: ENTITY_STATI.DRAFT,
  },
];

export function Products() {
  const initialRender = useRef(true);
  const user = useContext(UserOwnershipContext);
  const [userPrincipal] = useUser();
  const [displayedProducts, setDisplayedProducts] = useState<
    (Product & GenericDTO)[]
  >([]);
  const [lastKey, setLastKey] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState();
  const [searchInput, setSearchInput] = useState();
  const [statusFilter, setStatusFilter] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ownedProducts, setOwnedProducts] = useState(true);

  const {
    response: productResponse,
    isLoading: isFetchingProducts,
    invokeApi: getProductsPaginated,
  } = useGetProductsPaginated();

  useEffect(() => {
    if (user.products && !isFetchingProducts) {
      if (user.products && user.products.length > 0) {
        const products = ownedProducts
          ? encodeURIComponent(JSON.stringify(user.products))
          : null;
        getProductsPaginated({
          products,
        });
      } else {
        setOwnedProducts(false);
        getProductsPaginated();
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [user.products]);

  useEffect(() => {
    if (productResponse) {
      initialRender.current = false;
      setDisplayedProducts((prevState) => {
        return [...prevState, ...productResponse.products];
      });
      productResponse.lastKey
        ? setLastKey(
            encodeURIComponent(JSON.stringify(productResponse.lastKey))
          )
        : setLastKey(null);
    }
  }, [productResponse]);

  const loadMore = () => {
    if (!isFetchingProducts && lastKey) {
      getProductsPaginated({
        lastKey,
        name: searchTerm,
        status: statusFilter,
        products: ownedProducts
          ? encodeURIComponent(JSON.stringify(user.products))
          : null,
      });
    }
  };

  useEffect(() => {
    if (!initialRender.current) {
      if (ownedProducts) {
        setSearchTerm(searchInput);
        getProductsPaginated({
          status: statusFilter,
          name: searchInput,
          products: encodeURIComponent(JSON.stringify(user.products)),
        });
        setDisplayedProducts([]);
        initialRender.current = true;
      } else {
        setSearchTerm(searchInput);
        getProductsPaginated({
          status: statusFilter,
          name: searchInput,
        });
        setDisplayedProducts([]);
        initialRender.current = true;
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [ownedProducts]);

  const searchProduct = () => {
    if (!isFetchingProducts) {
      setSearchTerm(searchInput);
      const products = ownedProducts
        ? encodeURIComponent(JSON.stringify(user.products))
        : null;
      getProductsPaginated({
        name: searchInput,
        status: statusFilter,
        products,
      });
      setDisplayedProducts([]);
      initialRender.current = true;
    }
  };

  const filterChanged = (e: any) => {
    if (!isFetchingProducts) {
      setStatusFilter(e.target.value);
      const products = ownedProducts
        ? encodeURIComponent(JSON.stringify(user.products))
        : null;
      getProductsPaginated({
        status: e.target.value,
        name: searchTerm,
        products,
      });
      setDisplayedProducts([]);
      initialRender.current = true;
    }
  };

  const onResetSearch = () => {
    setStatusFilter(undefined);
    setSearchInput(undefined);
    setSearchTerm(undefined);
    getProductsPaginated();
    initialRender.current = true;
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const editProductDetail = () => {
    toggleModal();
  };

  return (
    <ProductsWrapper>
      <Modal isOpen={isModalOpen}>
        <EditProductForm handleCancel={toggleModal} />
      </Modal>
      <ProductActionContainer>
        <SearchDiv>
          <SearchBarList
            onSearchFieldChange={(e: any) => setSearchInput(e.target.value)}
            searchTerm={searchInput}
            onSearchSubmit={searchProduct}
            onKeyDown={(event: KeyboardEvent) =>
              event.key === "Enter" ? searchProduct() : null
            }
          />
          {user.isInternal && (
            <StyledDropdown>
              <Dropdown
                helperText=""
                label="Filter by"
                onChange={filterChanged}
                options={filterByOptions}
                value={statusFilter}
              />
            </StyledDropdown>
          )}
          {user &&
            user?.products &&
            user.products.length > 0 &&
            user?.isInternal && (
              <StyledSwitch
                label="Owned Products"
                labelPosition="right"
                onClick={() => setOwnedProducts((prevState) => !prevState)}
                size="default"
                checked={ownedProducts}
                disabled={initialRender.current}
              />
            )}
        </SearchDiv>
        {user &&
          userPrincipal?.isAuthorizedByScope("*:product:manage") &&
          user?.products &&
          user.products.length > 0 &&
          user.isInternal && (
            <Button
              color="secondary"
              variant="default"
              onClick={() => editProductDetail()}
            >
              Create New Product
            </Button>
          )}
      </ProductActionContainer>
      {initialRender.current ? (
        <ProductListSkeleton />
      ) : (
        <>
          {displayedProducts.length === 0 && !lastKey && (
            <ErrorPage
              subtitle="If you applied any filters, try and see if you made any errors"
              title="No items found"
              actionLabel="Search all Products"
              button={{
                color: "secondary",
                onClick: () => {
                  onResetSearch();
                },
              }}
            />
          )}
          {(lastKey || displayedProducts.length > 0) && (
            <ProductList
              products={displayedProducts}
              loadMore={loadMore}
              lastKey={lastKey}
            />
          )}
        </>
      )}
    </ProductsWrapper>
  );
}

export default Products;
