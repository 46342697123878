import { Col, Row } from "react-grid-system";
import styled, { useTheme } from "styled-components";

import { Card } from "./Card";

const ProductListContainer = styled.div`
  max-width: 100% !important;
  margin: 0 auto;
`;

const SkeletonContainer = styled.div`
  margin: 0 auto;
`;

const ProductCardSkeleton = styled(Card)`
  margin-bottom: 16px;
  background-color: ${(props) => props.theme.secondaryBackgroundColor};
`;

const ProductListContainerSkeleton = styled.div`
  padding: 20px 0;
`;

const ProductFooterSkeleton = styled.div`
  margin-top: 20px;
`;

export function ProductSkeletonLoader() {
  const theme = useTheme();

  return (
    <SkeletonContainer>
      <svg
        role="img"
        height="300"
        aria-labelledby="loading-aria"
        viewBox="0 0 500 300"
        preserveAspectRatio="none"
      >
        <title id="loading-aria">Loading...</title>
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          clipPath="url(#clipPath)"
          style={{ fill: `${theme.secondary}40` }}
        />
        <defs>
          <clipPath id="clipPath">
            <rect x="0" y="0" rx="0" ry="0" width="300" height="38" />
            <rect x="0" y="54" rx="0" ry="0" width="250" height="16" />
            <rect x="0" y="102" rx="0" ry="0" width="300" height="44" />
            <rect x="0" y="166" rx="0" ry="0" width="500" height="16" />
            <rect x="0" y="190" rx="0" ry="0" width="450" height="16" />
            <rect x="0" y="214" rx="0" ry="0" width="475" height="16" />
            <rect x="0" y="238" rx="0" ry="0" width="500" height="16" />
          </clipPath>
          <linearGradient id="fill">
            <stop
              offset="0.599964"
              stopColor={theme.textOnPrimary}
              stopOpacity="1"
            >
              <animate
                attributeName="offset"
                values="-2; -2; 1"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
            <stop
              offset="1.59996"
              stopColor={theme.textOnPrimary}
              stopOpacity="1"
            >
              <animate
                attributeName="offset"
                values="-1; -1; 2"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
            <stop
              offset="2.59996"
              stopColor={theme.textOnPrimary}
              stopOpacity="1"
            >
              <animate
                attributeName="offset"
                values="0; 0; 3"
                keyTimes="0; 0.25; 1"
                dur="2s"
                repeatCount="indefinite"
              />
            </stop>
          </linearGradient>
        </defs>
      </svg>
    </SkeletonContainer>
  );
}
export function ProductListSkeleton() {
  const theme = useTheme();
  return (
    <SkeletonContainer>
      <ProductListContainerSkeleton>
        <ProductListContainer>
          <Row gutterWidth={16}>
            {Array.from(new Array(9)).map((x, index) => {
              /*eslint-disable */
              return (
                <Col xs={12} md={6} lg={4} key={`key${index}`}>
                  <ProductCardSkeleton
                    body={
                      <svg
                        role="img"
                        width="235"
                        height="46"
                        aria-labelledby="loading-aria"
                        viewBox="0 0 235 46"
                        preserveAspectRatio="none"
                      >
                        <title id="loading-aria">Loading...</title>
                        <rect
                          x="0"
                          y="0"
                          width="100%"
                          height="100%"
                          clipPath="url(#clipPath)"
                          style={{ fill: `${theme.secondary}40` }}
                        />
                        <defs>
                          <clipPath id="clipPath">
                            <rect
                              x="0"
                              y="56"
                              rx="3"
                              ry="3"
                              width="410"
                              height="6"
                            />
                            <rect
                              x="0"
                              y="72"
                              rx="3"
                              ry="3"
                              width="380"
                              height="6"
                            />
                            <rect
                              x="0"
                              y="88"
                              rx="3"
                              ry="3"
                              width="178"
                              height="6"
                            />
                            <rect
                              x="0"
                              y="0"
                              rx="0"
                              ry="0"
                              width="235"
                              height="28"
                            />
                            <rect
                              x="0"
                              y="32"
                              rx="0"
                              ry="0"
                              width="145"
                              height="14"
                            />
                          </clipPath>
                          <linearGradient id="fill">
                            <stop
                              offset="0.599964"
                              stopColor="#f3f3f3"
                              stopOpacity="1"
                            >
                              <animate
                                attributeName="offset"
                                values="-2; -2; 1"
                                keyTimes="0; 0.25; 1"
                                dur="2s"
                                repeatCount="indefinite"
                              />
                            </stop>
                            <stop
                              offset="1.59996"
                              stopColor="#ecebeb"
                              stopOpacity="1"
                            >
                              <animate
                                attributeName="offset"
                                values="-1; -1; 2"
                                keyTimes="0; 0.25; 1"
                                dur="2s"
                                repeatCount="indefinite"
                              />
                            </stop>
                            <stop
                              offset="2.59996"
                              stopColor="#f3f3f3"
                              stopOpacity="1"
                            >
                              <animate
                                attributeName="offset"
                                values="0; 0; 3"
                                keyTimes="0; 0.25; 1"
                                dur="2s"
                                repeatCount="indefinite"
                              />
                            </stop>
                          </linearGradient>
                        </defs>
                      </svg>
                    }
                    footer={
                      <ProductFooterSkeleton>
                        <svg
                          role="img"
                          width="235"
                          height="17"
                          aria-labelledby="loading-aria"
                          viewBox="0 0 235 17"
                          preserveAspectRatio="none"
                        >
                          <title id="loading-aria">Loading...</title>
                          <rect
                            x="0"
                            y="0"
                            width="100%"
                            height="100%"
                            clipPath="url(#clipPath)"
                            style={{ fill: `${theme.secondary}40` }}
                          />
                          <defs>
                            <clipPath id="clipPath">
                              <rect
                                x="0"
                                y="56"
                                rx="3"
                                ry="3"
                                width="410"
                                height="6"
                              />
                              <rect
                                x="0"
                                y="72"
                                rx="3"
                                ry="3"
                                width="380"
                                height="6"
                              />
                              <rect
                                x="0"
                                y="88"
                                rx="3"
                                ry="3"
                                width="178"
                                height="6"
                              />
                              <rect
                                x="0"
                                y="0"
                                rx="0"
                                ry="0"
                                width="235"
                                height="17"
                              />
                            </clipPath>
                            <linearGradient id="fill">
                              <stop
                                offset="0.599964"
                                stopColor="#f3f3f3"
                                stopOpacity="1"
                              >
                                <animate
                                  attributeName="offset"
                                  values="-2; -2; 1"
                                  keyTimes="0; 0.25; 1"
                                  dur="2s"
                                  repeatCount="indefinite"
                                />
                              </stop>
                              <stop
                                offset="1.59996"
                                stopColor="#ecebeb"
                                stopOpacity="1"
                              >
                                <animate
                                  attributeName="offset"
                                  values="-1; -1; 2"
                                  keyTimes="0; 0.25; 1"
                                  dur="2s"
                                  repeatCount="indefinite"
                                />
                              </stop>
                              <stop
                                offset="2.59996"
                                stopColor="#f3f3f3"
                                stopOpacity="1"
                              >
                                <animate
                                  attributeName="offset"
                                  values="0; 0; 3"
                                  keyTimes="0; 0.25; 1"
                                  dur="2s"
                                  repeatCount="indefinite"
                                />
                              </stop>
                            </linearGradient>
                          </defs>
                        </svg>
                      </ProductFooterSkeleton>
                    }
                  />
                </Col>
              );
              /* eslint-enable */
            })}
          </Row>
        </ProductListContainer>
      </ProductListContainerSkeleton>
    </SkeletonContainer>
  );
}

export default ProductCardSkeleton;
