import { useTheme } from "styled-components";
import {
  StyledDrawer,
  StyledSidebarSearchField,
  StyledLink,
  LinkWrapper,
} from "./elements";
import { SidebarProps } from "./types";

export function Sidebar(props: SidebarProps) {
  const {
    links,
    productSidebar,
    withSearchField,
    onChange,
    onSubmit,
    open,
    modal,
    children,
    ...rest
  } = props;

  const theme = useTheme();

  return (
    <StyledDrawer
      open={open}
      dismissible
      {...rest}
      productSidebar={productSidebar}
      color={theme.primary}
      backgroundColor={theme.primaryBackgroundColor}
      borderColor={theme.primaryBorderColor}
    >
      {withSearchField && (
        <StyledSidebarSearchField
          label="Search"
          onChange={onChange}
          onSubmit={onSubmit}
        />
      )}
      {children}
      {!!links &&
        links.map((linkItem) => (
          <LinkWrapper key={linkItem.url}>
            <StyledLink
              key={linkItem.url}
              to={{
                pathname: `${linkItem.url}`,
                state: { signedUrl: `${linkItem.signedUrl}` },
              }}
              onClick={linkItem?.onClick}
              target={linkItem?.isTargetBlank ? "_blank" : ""}
            >
              <div title={linkItem.title}>{linkItem.title}</div>
            </StyledLink>
          </LinkWrapper>
        ))}
    </StyledDrawer>
  );
}

export default Sidebar;
