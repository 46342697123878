import { ENTITY_STATI } from "@apim/lib-portal-entities";
import {
  AsurionDoodleSpinner,
  Button,
  Dropdown,
} from "@soluto-private/mx-asurion-ui-react";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { API as APIelements } from "@stoplight/elements";
import { load } from "js-yaml";
import styled from "styled-components";
import { useGetSpecFileCDN } from "../../../lib/api";
import { UserOwnershipContext } from "../../../lib/hooks";
import {
  ActionsWrapper,
  MetaRow,
  MetaRowDropdown,
  MetaWrapper,
  MetaWrapperDropdown,
  SectionContentDivider,
  SectionHeader,
  SectionTitle,
  StyledTag,
} from "./elements";
import { BreadCrumbType } from "../../../constants";

interface IAPI {
  api: any;
  productName: string;
}

const StyledDropdown = styled(Dropdown)`
  width: 170px;
  background-color: ${(props) => props.theme.primaryBackgroundColor};
  color: ${(props) => props.theme.textOnPrimary};
`;

export const LoaderWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function convertYmltoJSON(spectext: string): any {
  const out = load(spectext);
  return out;
}

const parseSpec = (spec: string) => {
  return typeof spec === "object" ? spec : convertYmltoJSON(spec);
};

function ProductAPI({ api, productName }: IAPI) {
  const userOwnership = useContext(UserOwnershipContext);
  const [selectedSpec, setSelectedSpec] = useState("");
  const [spec, setSpec] = useState({});
  const history = useHistory();

  const ref: any = useRef<HTMLDivElement>();
  const firstRender = useRef(true);
  const [signedUrl, setSignedUrl] = useState<string>();
  const {
    response: specPayload,
    invokeApi: getSpecPayload,
    isLoading: isSpecLoading,
  } = useGetSpecFileCDN();

  useEffect(() => {
    const specParse = async () => {
      if (specPayload) {
        const parsedPayload = await parseSpec(specPayload);
        setSpec(parsedPayload);
      }
    };
    specParse();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [specPayload]);

  useEffect(() => {
    if (!firstRender.current) {
      getSpecPayload(null, undefined, null, selectedSpec);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedSpec]);

  useEffect(() => {
    if (signedUrl && signedUrl !== "") {
      getSpecPayload(null, undefined, null, signedUrl);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [signedUrl]);

  useEffect(() => {
    if (api.docs[0]) {
      setSignedUrl(api.docs[0].signedUrl as string);
      setSelectedSpec(api.docs[0].signedUrl as string);
      firstRender.current = false;
    }
  }, []);

  const formatDocArray = () => {
    const compare = (doc1: any, doc2: any) => {
      if (doc1.isDefault < doc2.isDefault) return 1;
      if (doc1.isDefault > doc2.isDefault) return -1;
      return 0;
    };
    api?.docs?.sort(compare);
    return api?.docs?.map((doc: any) => {
      return {
        name: `${doc.sk.split("#")[2]}`,
        value: doc.signedUrl,
      };
    });
  };

  const onSpecChange = (e: any) => {
    const docUrl = e.target.value;
    setSelectedSpec(docUrl);
  };

  return (
    <div ref={ref}>
      <MetaWrapper>
        {userOwnership.isInternal && (
          <ActionsWrapper>
            <Button
              size="small"
              variant="flat"
              onClick={() => {
                history.push(`/api/${api.title}`, {
                  link: `/products/${productName}`,
                  name: productName,
                } as BreadCrumbType);
              }}
            >
              View details page
            </Button>
          </ActionsWrapper>
        )}
        <MetaRow>
          {userOwnership.isInternal && (
            <>
              <StyledTag
                text={api?.isPublic ? "Public" : "Private"}
                type={api?.isPublic ? "attention" : "gray"}
                isColored={api?.isPublic}
              />
              <StyledTag
                text={
                  api?.status === ENTITY_STATI.COMPLETE
                    ? "Completed"
                    : "In progress"
                }
                type={
                  api?.status === ENTITY_STATI.COMPLETE ? "attention" : "gray"
                }
                isColored={api?.status === ENTITY_STATI.COMPLETE}
              />
            </>
          )}
        </MetaRow>
        <MetaRow>
          <SectionHeader>
            <SectionTitle>{api.title}</SectionTitle>
          </SectionHeader>
        </MetaRow>
        <MetaRow>
          <p>{api?.description}</p>
        </MetaRow>
      </MetaWrapper>
      <MetaWrapperDropdown>
        <MetaRowDropdown>
          <StyledDropdown
            label="Documents"
            options={formatDocArray() || []}
            onChange={onSpecChange}
            value={selectedSpec}
          />
        </MetaRowDropdown>
      </MetaWrapperDropdown>
      <SectionContentDivider />
      {isSpecLoading ? (
        <LoaderWrapper>
          <AsurionDoodleSpinner />
        </LoaderWrapper>
      ) : (
        // @ts-ignore
        <APIelements apiDescriptionDocument={spec} router="memory" />
      )}
    </div>
  );
}

export default ProductAPI;
