import { Checkbox, TextField } from "@soluto-private/mx-asurion-ui-react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { useCreateProductDoc } from "../api";
import { DOCUMENT_NAME_VALIDATION } from "../../constants";
import {
  ProductDocContainer,
  ProductDocMenu,
  ProductDocWrapper,
} from "./ProductDoc";
import { RichTextEditor, RichTextViewer } from "./RichTextEditor";

interface ICreateProductDocProp {
  product: string;
}

const DocumentFormSchema = Yup.object().shape({
  documentTitle: Yup.string()
    .min(3, "Document name required to be at least 3 characters long")
    .matches(
      DOCUMENT_NAME_VALIDATION,
      "Only alphanumeric characters,space,-,_,+ are allowed for the name."
    )
    .required("Document name required."),
});

export type CreateProductDocProp = ICreateProductDocProp;

const DEFAULT_EDITOR_HEIGHT = "min-height: 500px !important";

const NewDocEditor = styled(RichTextEditor)`
  ${DEFAULT_EDITOR_HEIGHT}
`;

const NewDocHeader = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  & > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export function CreateProductDoc({ product }: ICreateProductDocProp) {
  const [pendingPayload, setPendingPayload] = useState(
    "<h3>Start editing here!</h3>"
  );
  const [saveToPending, setSaveToPending] = useState(true);
  const [documentTitle, setDocumentTitle] = useState(`New ${product} Document`);
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [productDocName, setProductDocName] = useState(
    `New ${product} Document`
  );
  const [previewMode, setPreviewMode] = useState(false);

  const {
    response: docSaveResponse,
    isLoading: isDocumentSending,
    invokeApi: postDocument,
  } = useCreateProductDoc(product);
  const history = useHistory();

  // Document change event
  const handleOnDocumentChange = (payload: string) => {
    setPendingPayload(payload);
  };

  const handleDocumentTitleChange = (e: any) => {
    setDocumentTitle(e.target.value);
    setProductDocName(e.target.value);
  };

  const handleDocumentSave = async () => {
    const payloadBuffer = new Blob([pendingPayload], {
      type: "text/plain",
    });
    const formData = new FormData();
    formData.append("file", payloadBuffer, `${productDocName}.txt`);
    formData.append("parent", "PRODUCT");
    formData.append("resource", product);
    await postDocument(null, formData, {
      "Content-Type": "multipart/form-data",
    });
    window.location.reload();
  };

  const handleCancellation = () => {
    history.push(`/products/${product}`);
  };

  useEffect(() => {
    setFieldsDisabled(isDocumentSending);

    return () => {};
  }, [setFieldsDisabled, isDocumentSending, pendingPayload]);

  const handlePreviewClick = () => {
    setPreviewMode(!previewMode);
  };

  useEffect(() => {
    if (docSaveResponse) {
      history.push(`/products/${product}/docs/${documentTitle}`);
    }
  }, [docSaveResponse, documentTitle, history, product]);
  const formik = useFormik({
    initialValues: {
      documentTitle: "",
    },
    onSubmit: () => {},
    validateOnBlur: true,
    validationSchema: DocumentFormSchema,
  });
  return (
    <ProductDocWrapper>
      <ProductDocContainer>
        <ProductDocMenu
          onCancelClicked={handleCancellation}
          onSaveClicked={handleDocumentSave}
          onPreviewClicked={handlePreviewClick}
          controlsDisabled={fieldsDisabled}
          previewText={previewMode ? "Exit Preview Mode" : "Preview"}
        />
        <NewDocHeader>
          <Checkbox
            label="Save Document as Draft"
            checked={saveToPending}
            onChange={() => setSaveToPending(!saveToPending)}
            disabled={fieldsDisabled}
          />
          <TextField
            value={documentTitle}
            disabled={fieldsDisabled}
            label="Document Name"
            defaultValue={formik.values.documentTitle}
            name="documentTitle"
            fieldStatus={formik.errors.documentTitle ? "error" : "default"}
            helperText={
              formik.errors.documentTitle ? formik.errors.documentTitle : ""
            }
            onChange={(value) => {
              formik.handleChange(value);
              handleDocumentTitleChange(value);
            }}
          />
        </NewDocHeader>
        {previewMode ? (
          <RichTextViewer content={pendingPayload} />
        ) : (
          <NewDocEditor
            content={pendingPayload}
            onChange={handleOnDocumentChange}
            editorStyle={DEFAULT_EDITOR_HEIGHT}
            disabled={fieldsDisabled}
          />
        )}
      </ProductDocContainer>
    </ProductDocWrapper>
  );
}
