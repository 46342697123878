import { useContext } from "react";

import {
  StyledContainer,
  HalfContainers,
  LeftContainer,
  RightContainer,
} from "./elements";
// import { PersonalDetails } from "./PersonalDetails";
// import { ActivityDetails } from "./ActivityDetails";
import { UserTeams } from "../Team";
import { UserOwnershipContext } from "../../lib/hooks/user/user.context";

export function UserDetails() {
  const userOwnership = useContext(UserOwnershipContext);
  return (
    <StyledContainer>
      <LeftContainer>
        <HalfContainers>{/* <PersonalDetails /> */}</HalfContainers>
        <HalfContainers>
          {userOwnership.isInternal && <UserTeams />}
        </HalfContainers>
      </LeftContainer>
      <RightContainer>{/* <ActivityDetails /> */}</RightContainer>
    </StyledContainer>
  );
}

export default UserDetails;
