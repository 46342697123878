import styled from "styled-components";
import {
  AsurionDoodleSpinner,
  Button,
  Dropdown,
} from "@soluto-private/mx-asurion-ui-react";
import { useFormik } from "formik";

const StyledForm = styled.form`
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 5px;
`;

const SubmitButton = styled(Button)`
  align-self: flex-end;
  color: ${(props) => props.theme.textOnSecondary};
`;

const FieldContainer = styled.div`
  && {
    min-width: 20%;
    width: 40%;
  }
`;

interface AddRoleFormProps {
  handleSubmit: any;
  roles: any;
  ownerships: any;
  addIsLoading: boolean;
}

export function AddRoleForm({
  handleSubmit,
  ownerships,
  roles,
  addIsLoading,
}: AddRoleFormProps) {
  const formik = useFormik({
    initialValues: {
      ownership: "",
      role: "",
    },
    onSubmit: handleSubmit,
  });

  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <FieldContainer>
        <Dropdown
          label="Ownership"
          onChange={(value) => {
            formik.handleChange(value);
          }}
          name="ownership"
          options={ownerships}
          value={formik.values.ownership}
        />
      </FieldContainer>
      <FieldContainer>
        <Dropdown
          label="Role"
          onChange={(value) => {
            formik.handleChange(value);
          }}
          name="role"
          options={roles}
          value={formik.values.role}
        />
      </FieldContainer>
      <SubmitButton type="submit" disabled={addIsLoading}>
        <>
          Add Role
          {addIsLoading && <AsurionDoodleSpinner width="20px" />}
        </>
      </SubmitButton>
    </StyledForm>
  );
}

export default AddRoleForm;
