import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { CreateHelpDoc } from "./CreateHelpDoc";
import { SidebarHelpMenu } from "./SidebarHelpMenu";
import { HelpDocContentSection, HelpWrapper } from "./HelpDocStyled";
import { useGetAllDocs, useGetAllPartnerDocs } from "../../lib/api";
import { HelpDoc } from "./HelpDoc";
import { useUser, UserOwnershipContext } from "../../lib/hooks";

export function Help() {
  const [useUserData] = useUser();
  const isAdmin = useUserData?.isAuthorizedByScope(`TEAM#aaim:product:manage`);
  const canModify = Boolean(isAdmin);
  const { helpDoc } = useParams() as any;
  // const { state } = useLocation() as any;
  const [allDocContent, setAllDocContent] = useState<any>();
  const user = useContext(UserOwnershipContext);
  const {
    response: getAllDocsResponse,
    isLoading: isFetchingGetAllDocs,
    invokeApi: getAllDoc,
  } = useGetAllDocs();
  const {
    response: getAllPartnerDocsResponse,
    isLoading: isFetchingGetAllPartnerDocs,
    invokeApi: getAllPartnerDoc,
  } = useGetAllPartnerDocs();
  const handleOnDocumentUpdate = () => {};
  useEffect(() => {
    const getDoc = async () => {
      if (user?.isInternal) {
        await getAllDoc();
      } else {
        await getAllPartnerDoc();
      }
    };
    getDoc();
    // eslint-disable-next-line
  }, [user?.isInternal]);

  useEffect(() => {
    getAllDocsResponse && setAllDocContent(getAllDocsResponse);
    // eslint-disable-next-line
  }, [getAllDocsResponse]);
  useEffect(() => {
    getAllPartnerDocsResponse && setAllDocContent(getAllPartnerDocsResponse);
    // eslint-disable-next-line
  }, [getAllPartnerDocsResponse]);
  return (
    <HelpWrapper>
      <SidebarHelpMenu
        docsResponse={allDocContent}
        isFetchingDocs={isFetchingGetAllDocs || isFetchingGetAllPartnerDocs}
      />

      <section id="helpDocuments">
        {helpDoc !== "new" ? (
          allDocContent?.map((value: any) => {
            return (
              <HelpDocContentSection
                key={value.sk.split("#")[1]}
                name={value.sk.split("#")[1]}
                id={value.sk.split("#")[1]}
              >
                <HelpDoc
                  signedUrl={value.signedUrl}
                  helpDocTitle={value.payload}
                  canModify={canModify}
                  onChange={handleOnDocumentUpdate}
                />
              </HelpDocContentSection>
            );
          })
        ) : (
          <CreateHelpDoc />
        )}
      </section>
    </HelpWrapper>
  );
}

export default Help;
