import styled from "styled-components";
import { Drawer } from "@rmwc/drawer";
import { TextField } from "@soluto-private/mx-asurion-ui-react";

import { SidebarProps } from "./types";
import { FooterLink } from "../Link";

export const StyledDrawer = styled(Drawer)<SidebarProps>`
  height: 100vh;
  position: sticky;
  top: 75px;
  padding: 10px 16px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  @media print {
    display: none !important;
  }
  border-right: 1px solid ${(props) => props.borderColor};
`;

export const StyledSidebarSearchField = styled(TextField)`
  border: ${(props) => props.theme.primaryBorderColor};
`;

export const StyledLink = styled(FooterLink)`
  & {
    font-size: 14px;
    margin-left: 5px;
    color: ${(props) => props.theme.textOnPrimary};
    text-decoration: none;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    color: ${(props) => props.theme.invertedTextOnPrimary};
  }
  &&& {
    &:hover.active-link {
      border-radius: 8px;
      background: ${(props) => props.theme.secondaryBackgroundColor};
    }
    &.active-link {
      border-radius: 8px;
      background: ${(props) => props.theme.secondaryBackgroundColor};
    }
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;
